import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LivingRoomIcon from "@material-ui/icons/Weekend";
import BackYardIcon from "@material-ui/icons/Deck";
import BedroomIcon from "@material-ui/icons/Hotel";
import HomeOfficeIcon from "@material-ui/icons/LaptopMac";

import colors from "../colors";
import { useAppContext } from "../context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  cardRoot: {
    width: "100%",
    transition: `background-color ${process.env.REACT_APP_TRANSITION_DURATION}`,
    backgroundColor: (prop) =>
      prop.darkTheme === "dark" ? colors["01dp"] : undefined,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "& svg": {
      fontSize: "80px",
      transition: `color ${process.env.REACT_APP_TRANSITION_DURATION}`,
      color: theme.palette.primary.dark,
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "start",
  },
}));

const AllRoomsScreen = () => {
  const theme = useTheme();
  const classes = useStyles({ darkTheme: theme.palette.type });

  const [rooms, setRooms] = useState([]);

  const { handleRoomState, userData } = useAppContext();

  useEffect(() => {
    const initialRequest = async () => {
      try {
        if (userData && userData.id) {
          const { data } = await Axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/${userData.id}/rooms`,
            {
              headers: {
                "access-token": userData.token,
              },
            }
          );
          if (data) {
            setRooms(data);
          }
        }
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.statusText === "Expired token"
        ) {
          // expiredToken();
        } else {
          console.log(error);
        }
      }
    };

    initialRequest();
    // eslint-disable-next-line
  }, [userData]);

  return (
    <Grid container spacing={3} className={classes.root} justify="space-evenly">
      {rooms.length > 0 &&
        rooms.map(({ name, type, _id: id }, idx) => (
          <Grid className={classes.gridItem} item xs={6} lg={4} key={idx}>
            <Card className={classes.cardRoot} key={id}>
              <CardActionArea onClick={() => handleRoomState(id, name)}>
                <CardMedia className={classes.iconContainer}>
                  {type === "living-room" && <LivingRoomIcon />}
                  {type === "bed-room" && <BedroomIcon />}
                  {type === "office-room" && <HomeOfficeIcon />}
                  {type === "backyard" && <BackYardIcon />}
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    color="primary"
                  >
                    {name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default AllRoomsScreen;
