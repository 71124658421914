import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

import { useAppContext } from "../context";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

export default function DiscreteSlider({
  id,
  name,
  marks,
  step,
  type,
  min,
  max,
}) {
  const classes = useStyles();
  const { room, value, handleValueUpdate } = useAppContext();
  const [stepsMark, setStepsMark] = useState([]);

  const handleChange = (event, newValue) => {
    handleValueUpdate(room.id, id, name, newValue);
  };

  useEffect(() => {
    let steps = [];
    for (let st = 0; st < marks; st++) {
      if (st === 0) {
        steps = [...steps, { label: "Min", value: min }];
      } else if (st === marks - 1) {
        steps = [...steps, { label: "Max", value: max }];
      } else {
        steps = [...steps, { label: st.toString(), value: min + st * step }];
      }
    }
    setStepsMark(steps);
  }, [marks, min, max, step]);

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        Level
      </Typography>
      {stepsMark.length > 0 && (
        <Slider
          value={
            value && value[room.id] && value[room.id][id]
              ? value[room.id][id]
              : 0
          }
          // getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={step}
          marks={stepsMark}
          min={min}
          max={max}
          onChange={handleChange}
        />
      )}
    </div>
  );
}
