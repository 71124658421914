/**This custom hook check if the 'smartHomeRoom' state has been set, if not it log in the existing current room */

import Axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppContext } from "../context";

export const useRoomLogin = () => {
  let { roomId } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const { userData, socket, room, handleRoomState } = useAppContext();

  useEffect(() => {
    const initialRequest = async () => {
      try {
        if (userData && userData.id) {
          const { data } = await Axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/${userData.id}/rooms`,
            {
              headers: {
                "access-token": userData.token,
              },
            }
          );
          if (data) {
            const obj = {};
            data.forEach(({ _id, name }) => (obj[_id] = name));
            // console.log(obj);
            if (obj && obj[roomId] && !room.id) {
              handleRoomState(roomId, obj[roomId]);
              socket.emit("room", roomId);
              setLoading(false);
            } else if (obj && !obj[roomId]) {
              history.push("/rooms");
            } else {
              setLoading(false);
            }
          }
        }
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.statusText === "Expired token"
        ) {
          // expiredToken();
        } else {
          console.log(error);
        }
      }
    };
    initialRequest();
    //eslint-disable-next-line
  }, []);

  return loading;
};
