import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DarkThemeIcon from "@material-ui/icons/Brightness4";
import LightThemeIcon from "@material-ui/icons/Brightness5";

import { useAppContext } from "../context";
import colors from "../colors";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: `background-color ${process.env.REACT_APP_TRANSITION_DURATION}`,
    color: (styles) =>
      styles.darkTheme ? theme.palette.primary.dark : undefined,
    backgroundColor: (styles) =>
      styles.darkTheme ? colors["04dp"] : undefined,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const {
    toggleDrawer,
    handleToggleDrawer,
    darkTheme,
    toggleDarkTheme,
  } = useAppContext();
  const styles = { darkTheme };
  const classes = useStyles(styles);

  return (
    <>
      <AppBar
        className={classes.root}
        position="sticky"
        color={darkTheme ? "default" : "primary"}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => handleToggleDrawer(!toggleDrawer)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Smart Home App
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="dark theme icon"
            onClick={() => toggleDarkTheme()}
          >
            {darkTheme ? <LightThemeIcon /> : <DarkThemeIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
