import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

import Card from "../components/SmartCard";
import { useAppContext } from "../context";
import { useRoomLogin } from "../hooks/useRoomLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: "1rem",
  },
  fullHeight: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  deviceError: {
    marginTop: "5rem",
    "& span:first-child": {
      display: "block",
    },
  },
}));

const RoomScreen = () => {
  const classes = useStyles();
  let { roomId } = useParams();
  const loading = useRoomLogin();
  const [devices, setDevices] = useState([]);
  const [devicesKeys, setDevicesKeys] = useState({});
  const { room, value, setValue, socket } = useAppContext();

  useEffect(() => {
    const getDevices = async () => {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomId}`
      );
      if (data && data.devices) {
        const devicesArr = data.devices;
        setDevices(devicesArr);
      }
    };
    getDevices();
  }, [roomId]);

  useEffect(() => {
    if (!value[roomId] && devices.length > 0) {
      // console.log("Let's create the room state");
      const idArr = [];
      const devs = {};
      devices.forEach(({ id, name }) => {
        idArr.push(id);
        devs[name] = id;
      });
      const obj = {};
      for (const key of idArr) {
        obj[key] = 0;
      }
      // console.log(obj);
      setValue({ ...value, [roomId]: obj });
      setDevicesKeys(devs);
    }
    //eslint-disable-next-line
  }, [devices]);

  useEffect(() => {
    if (devices.length > 0 && Object.keys(devicesKeys).length > 0) {
      const nameArr = [];
      devices.map(({ name }) => nameArr.push(name));
      for (const key of nameArr) {
        socket.on(key, (state) => {
          console.log(`${key} event: ${state}`);
          setValue({
            ...value,
            [roomId]: {
              ...value[roomId],
              [devicesKeys[key]]: JSON.parse(state),
            },
          });
        });
      }
    }
    socket.on();
    //eslint-disable-next-line
  }, [devices, socket, devicesKeys]);

  return (
    !loading && (
      <>
        <Typography
          align="center"
          className={classes.title}
          component="h5"
          variant="h5"
        >
          {room.name}
        </Typography>
        {devices && devices.length !== 0 ? (
          devices.map((device, idx) => <Card key={idx} deviceInfo={device} />)
        ) : (
          <Typography
            align="center"
            className={classes.deviceError}
            component="h6"
            variant="h6"
          >
            <span>
              <ErrorIcon fontSize="large" />
            </span>
            <span>No devices found!</span>
          </Typography>
        )}
      </>
    )
  );
};

export default RoomScreen;
