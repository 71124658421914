import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useHistory } from "react-router-dom";

import { usePersistedState } from "./hooks/usePersistedState";
import { AppContext } from "./context";
import Routes from "./Routes";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { auth } from "./services/firebase";

const endpoint = process.env.REACT_APP_BACKEND_URL;

function App() {
  const history = useHistory();

  const [darkTheme, setDarkTheme] = usePersistedState(
    "smartHomeDarkTheme",
    false
  );

  const [autoSwitchDarkTheme, setAutoSwitchDarkTheme] = usePersistedState(
    "smartHomeAutoSwitchDarkTheme",
    false
  );

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: darkTheme ? purple[200] : purple[800],
            dark: "#ce93d8",
          },
          background: {
            paper: darkTheme ? "#121212" : "#FFFFFF",
            default: "#FAFAFA",
          },
          type: darkTheme ? "dark" : "light",
        },
      }),
    [darkTheme]
  );

  const [socket] = useState(
    io(endpoint, {
      transports: ["websocket"],
    })
  );

  const [room, setRoom] = usePersistedState("smartHomeRoom", {
    id: "",
    name: "",
  });

  const [toggleDrawer, setToggleDrawer] = usePersistedState(
    "smartHomeToggleDrawer",
    false
  );

  const [value, setValue] = usePersistedState("smartHomeValues", {});

  const [userAuthenticated, setUserAuthenticated] = usePersistedState(
    "smartHomeUserAuth",
    false
  );

  const [userData, setUserData] = usePersistedState("smartHomeUserData", "");

  auth.onAuthStateChanged((user) => {
    if (user) {
      // console.log(user);
      setUserAuthenticated(true);
    } else {
      // console.log("No logged in");
      setUserAuthenticated(false);
      return history.push("/", { from: history.location.pathname });
    }
  });

  //socket connection
  useEffect(() => {
    if (!socket.connected) {
      socket.on("connect", () => {
        socket.emit("init", "Client is Connected");
      });
    }
    return () => {
      socket.on("disconnect", () => {
        console.log(socket.connected); // false
      });
    };
    // eslint-disable-next-line
  }, [socket, userAuthenticated]);

  //room change
  useEffect(() => {
    if (room.id) {
      socket.emit("room", room.id);
      history.push(`/rooms/${room.id}`);
    }
  }, [room, history, socket]);

  //Theme auto switch
  useEffect(() => {
    if (autoSwitchDarkTheme) {
      const date = new Date();
      const hours = date.getHours();
      if (hours >= 18 || hours < 6) {
        setDarkTheme(true);
      } else {
        setDarkTheme(false);
      }
    }
  }, [autoSwitchDarkTheme, setDarkTheme]);

  //userAuthenticated
  useEffect(() => {
    if (!userAuthenticated) {
      auth.signOut();
    }
  }, [userAuthenticated]);

  const handleToggleDrawer = (value) => {
    if (value) {
      return setToggleDrawer(value);
    } else {
      return setToggleDrawer(false);
    }
  };

  const handleRoomState = (id, name) => {
    if (id === "") {
      socket.emit("leave", room.id);
      setRoom({ id, name: "" });
    } else {
      setRoom({ id, name });
    }
  };

  const toggleDarkTheme = () => {
    setDarkTheme((prevState) => !prevState);
  };

  const handleAutoSwitchDarkTheme = (value) => {
    setAutoSwitchDarkTheme(value);
  };

  const handleValueUpdate = (roomID, deviceID, deviceName, newValue) => {
    // console.log(roomID, deviceID, deviceName, newValue);
    const roomCopy = value[roomID];
    // console.log(roomCopy);
    roomCopy[deviceID] = newValue;
    // console.log(roomCopy);
    setValue({ ...value, [roomID]: roomCopy });
    socket.emit(deviceName, { room: roomID, state: newValue });
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          value,
          setValue,
          handleValueUpdate,
          socket,
          room,
          handleRoomState,
          toggleDrawer,
          handleToggleDrawer,
          darkTheme,
          toggleDarkTheme,
          autoSwitchDarkTheme,
          handleAutoSwitchDarkTheme,
          userAuthenticated,
          setUserAuthenticated,
          userData,
          setUserData,
        }}
      >
        <Routes />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
