import React from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import { useAppContext } from "../context";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "600px",
  },
  label: {
    justifyContent: "space-between",
    margin: "0 1rem",
    width: "100%",
  },
}));
const SettingsScreen = () => {
  const classes = useStyles();
  const {
    autoSwitchDarkTheme,
    handleAutoSwitchDarkTheme,
    darkTheme,
    toggleDarkTheme,
  } = useAppContext();

  const handleAutoChange = (event) => {
    handleAutoSwitchDarkTheme(event.target.checked);
  };

  return (
    <FormControl className={classes.root} component="fieldset" fullWidth>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className={classes.label}
          value="start"
          control={
            <Switch
              color="primary"
              checked={autoSwitchDarkTheme}
              onChange={handleAutoChange}
            />
          }
          label="Auto Switch Dark Mode"
          labelPlacement="start"
        />
        <FormControlLabel
          className={classes.label}
          value="start"
          control={
            <Switch
              color="primary"
              checked={darkTheme}
              onChange={() => toggleDarkTheme()}
            />
          }
          label="Dark Mode"
          labelPlacement="start"
        />
      </FormGroup>
    </FormControl>
  );
};

export default SettingsScreen;
