import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import DarkThemeIcon from "@material-ui/icons/Brightness4";
import LightThemeIcon from "@material-ui/icons/Brightness5";

import { useAppContext } from "../context";
import { auth } from "../services/firebase";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  brandLogo: {
    width: 150,
    height: 150,
    backgroundColor: "yellow",
    margin: "1rem auto",
  },
  darkMode: {
    color: (prop) =>
      prop.darkTheme === "dark" ? theme.palette.primary.dark : undefined,
    "& .MuiListItemIcon-root": {
      color: (prop) =>
        prop.darkTheme === "dark" ? theme.palette.primary.dark : undefined,
    },
  },
}));

export default function SwipeableTemporaryDrawer() {
  const theme = useTheme();
  const classes = useStyles({ darkTheme: theme.palette.type });
  const history = useHistory();

  const {
    toggleDrawer,
    handleToggleDrawer,
    darkTheme,
    toggleDarkTheme,
    handleRoomState,
    setUserAuthenticated,
    setUserData,
  } = useAppContext();

  const handleClick = (path) => {
    if (path !== "toggleTheme" && path !== "/settings" && path !== "/help") {
      if (path === "/signout") {
        auth.signOut();
        setUserAuthenticated(false);
        handleRoomState("");
        setUserData({});
        return history.push("/");
      }
      history.push(path);
      handleRoomState("");
    } else if (path === "/settings" || path === "/help") {
      history.push(path);
    } else {
      toggleDarkTheme();
    }
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => handleToggleDrawer(false)}
      onKeyDown={() => handleToggleDrawer(false)}
    >
      <List className={classes.darkMode}>
        <ListItem button key={"rooms"} onClick={() => handleClick("/rooms")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Rooms"} />
        </ListItem>
        {/* <ListItem button key={"leave"} onClick={() => handleClick("/")}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Leave Room"} />
        </ListItem> */}
        <ListItem
          button
          key={"Theme"}
          onClick={() => handleClick("toggleTheme")}
        >
          <ListItemIcon>
            {darkTheme ? <LightThemeIcon /> : <DarkThemeIcon />}
          </ListItemIcon>
          <ListItemText primary={darkTheme ? "Light Mode" : "Dark Mode"} />
        </ListItem>
        <ListItem
          button
          key={"setting"}
          onClick={() => handleClick("/settings")}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
        <ListItem button key={"help"} onClick={() => handleClick("/help")}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={"Help"} />
        </ListItem>
        <ListItem
          button
          key={"signout"}
          onClick={() => handleClick("/signout")}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary={"Sign Out"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={"left"}
          open={toggleDrawer}
          onClose={() => handleToggleDrawer(false)}
          onOpen={() => handleToggleDrawer(true)}
        >
          <div
            className={classes.brandLogo}
            onClick={() => handleClick("/rooms")}
          ></div>
          <Divider />
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
