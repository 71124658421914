import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { useAppContext } from "../context";

export const useRefreshToken = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { userData, setUserData, setUserAuthenticated } = useAppContext();

  useEffect(() => {
    setLoading(true);
    const refresToken = async () => {
      try {
        // console.log(userData.dueDate, userData.dueDate - Date.now());
        if (userData && userData.dueDate < Date.now()) {
          const {
            data,
          } = await Axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/login`,
            { user: userData }
          );
          // console.log(data.dueDate);
          if (data && data.token && data.dueDate) {
            // console.log(data.token);
            const { token, dueDate } = data;
            setUserData({ ...userData, token, dueDate });
          }
        }
        setTimeout(() => {
          setLoading(false);
        }, 700);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.statusText === "Expired token"
        ) {
          console.log("Expired token...");
        } else {
          setUserAuthenticated(false);
        }
        return true;
      }
    };

    refresToken();
    //eslint-disable-next-line
  }, [history.location.pathname]);

  return loading;
};
