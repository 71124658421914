import React from "react";
import { Route, Switch } from "react-router-dom";
import SignInScreen from "./screens/SignInScreen";
// import HomeScreen from "./screens/HomeScreen";
import RoomScreen from "./screens/RoomScreen";
import AllRoomsScreen from "./screens/AllRoomsScreen";
import SettingsScreen from "./screens/SettingsScreen";

import { Backdrop, CircularProgress } from "@material-ui/core";
import { Container, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "./components/AppBar";
import Drawer from "./components/SwipeableDrawer";

import { useRefreshToken } from "./hooks/useRefreshToken";
import { useAppContext } from "./context";
export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <SignInScreen />
      </Route>
      <Route exact path="/rooms">
        <LayoutComponent>
          <AllRoomsScreen />
        </LayoutComponent>
      </Route>
      <Route path="/rooms/:roomId">
        <LayoutComponent>
          <RoomScreen />
        </LayoutComponent>
      </Route>
      <Route path="/settings">
        <LayoutComponent>
          <SettingsScreen />
        </LayoutComponent>
      </Route>
    </Switch>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "1rem",
  },
  fullHeight: {
    height: "100vh",
    overflowY: "auto",
    transition: `background-color ${process.env.REACT_APP_TRANSITION_DURATION}`
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LayoutComponent = ({ children }) => {
  const classes = useStyles();
  const { userAuthenticated } = useAppContext();
  const loading = useRefreshToken();

  return (
    <Paper className={classes.fullHeight}>
      <AppBar />
      <Drawer />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="md" className={classes.root}>
        {userAuthenticated && children}
      </Container>
    </Paper>
  );
};
