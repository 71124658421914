import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Slider from "./Slider";
import Switch from "./Switch";

import colors from "../colors";
import { useAppContext } from "../context";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "auto",
    marginBottom: "2rem",
    transition: `background-color ${process.env.REACT_APP_TRANSITION_DURATION}`,
    backgroundColor: (styles) =>
      styles.darkTheme ? colors["01dp"] : undefined,
  },
  actions: {
    padding: "1rem 2rem",
  },
});

export default function SmartCard({ deviceInfo }) {
  const { darkTheme } = useAppContext();
  const styles = { darkTheme };
  const classes = useStyles(styles);
  const { name, component, type, min, max, marks, step, id } = deviceInfo;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        {component === "slider" && (
          <Slider
            id={id}
            name={name}
            marks={marks}
            step={step}
            type={type}
            min={min}
            max={max}
          />
        )}
        {(component === "switch" || component === "display") && (
          <Switch name={name} type={type} id={id} />
        )}
      </CardActions>
    </Card>
  );
}
