import React from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { useAppContext } from "../context";

export default function FormControlLabelPosition({ name, type, id }) {
  const { room, value, handleValueUpdate } = useAppContext();

  const handleChange = (event) => {
    if (type === "output") {
      // const tempRoom = { ...value[room.id] };
      // console.log(tempRoom);

      // const newValue = event.target.checked;

      // tempRoom[name] = newValue;
      // setValue({ ...value, [room.id]: tempRoom });
      // socket.emit(name, { room: room.id, state: newValue });
      const newValue = event.target.checked;
      handleValueUpdate(room.id, id, name, newValue);
    }
  };

  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="end"
          control={
            <Switch
              checked={
                value && value[room.id] && value[room.id][id]
                  ? value[room.id][id]
                  : false
              }
              onChange={handleChange}
              name="checkedA"
              color={type === "input" ? "secondary" : "primary"}
            />
          }
          label="On"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
}
