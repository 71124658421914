const colors = {
    "00dp": "#121212",
    "01dp": "#1E1E1E",
    "02dp": "#232323",
    "03dp": "#252525",
    "04dp": "#272727",
    "06dp": "#2C2C2C",
    "08dp": "#2E2E2E",
    "12dp": "#333333",
    "16dp": "#363636",
    "24dp": "#383838",
}

export default colors;